// console.log(" sADSADAMMAL");
//     var app = angular.module('myApp', ['ngRoute']).config(function($interpolateProvider){
//         $interpolateProvider.startSymbol('<%').endSymbol('%>');
//     });
//
//     app.controller('chatController', function ($scope, $http, $timeout, $interval) {
//
//       $scope.chatMsg = '';
//
//       var time = new Date().getTime()/1000;
//
//       $scope.sendMessage = function(msg){
//         console.log(String(msg));
//         if (msg) {
//           var data = {
//                         msg: String(msg),
//                         auctionId:  '{{$auction->id}}'
//                       };
//
//                       console.log(data);
//           $http({
//                   method: 'POST',
//                   url: '{{url("/dashboard/sendmessage")}}',
//                   headers: { 'Content-Type' : 'application/x-www-form-urlencoded' },
//                   data: $.param(data)
//               });
//
//             $scope.renderMessage(msg, '{{auth()->user()->id}}');
//           $scope.chatMsg = '';
//         }
//         };
//
//         $scope.renderMessage = function (msg, userId) {
//           console.log(msg);
//           switch (userId) {
//             case {{auth()->user()->id}}:
//               var template = jQuery.parseHTML('<li class="message hidden mine">' + msg + '</li>')
//               $( ".messages" ).append(template);
//               $timeout(function(){$(template).removeClass('hidden');},50);
//               break;
//             default:
//               var template = jQuery.parseHTML('<li class="message hidden theirs">' + msg + '</li>' )
//               $( ".messages" ).append(template);
//               chatSound.play();
//               $timeout(function(){$(template).removeClass('hidden');},50);
//           }
//         };
//
//         var url = '/api/chat/getNew/{{$auction->id}}/'+time;
//         console.log(" URL "+url);
//
//
//         function getData(){
//           $http.get('{{ url('/') }}'+url).success(function ($data) {
//             for (var i = 0; i < $data.length; i++) {
//               $scope.renderMessage($data[i].message, $data[i].userId);
//               time = new Date().getTime()/1000;
//             }
//           });
//         }
//
//
//         $interval(function() {
//           getData();
//         },3000);
//
//
//         var chatSound = new Audio('{{ asset('assets/chat.mp3') }}');
//
//
//         if(false){
//           console.log('http://localhost:8890');
//
//           var socket = io.connect('http://localhost:3000');
//           socket.on('connect', function(){
//             socket.emit('joinRoom', "room{{$auction->id}}");
//           });
//
//           var lastMsg = 0;
//
//           socket.on('newChatMsg', function (data) {
//             data = JSON.parse(data);
//
//             console.log(lastMsg+" != "+data.time);
//             if(lastMsg != data.time){
//               lastMsg = data.time;
//               $scope.renderMessage(data.message, data.userId);
//             }
//           });
//         }
//
//     });
