//-----------------------------Variable declaration-----------------------------
var jetDeal = {
      behaviours: {}
    };

//-----------------Usefull variables declaration for later use------------------
jetDeal.variables = {
  spaceSections: (function () {
    var count = 0;
    $('.space-frame').each(function() {
      if (!$(this).hasClass('show-for-large')) {
        count++;
      }
    });
    return count;
  })(),
  viewportWidth: window.innerWidth,
  viewportHeight: window.innerHeight
}

//------------------------------Jetdeal behaviours------------------------------
jetDeal.behaviours = {
  frontPage: {
    spaceJS: function () {
      if (jetDeal.variables.viewportWidth >= 1024 && document.getElementById('how-it-works') != null) {
        // Init Space.JS
        //Space.init();
        return true;
      } else {
        return false;
      }
    },
    onLoad: function (vH, numSec) {
      if (this.spaceJS()) {
        vH = vH * 1.65;
        $('.how-it-works').prev().css('margin-bottom', numSec * vH + 'px');
      } else {
        console.log('Space JS not initialized!');
        return;
      }
    },
    emptyLegs: function () {

      $('.slider-foreground').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        slide: '.emptyleg__slide',
        dots: false,
        arrows: true,
        centerMode: true,
        focusOnSelect: true,
        mobileFirst: true,
        prevArrow: '<i class="slick-prev"></i>',
        nextArrow: '<i class="slick-next"></i>',
        responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4
          }
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 0,
          settings: {
            slidesToShow: 1
          }
        }
      ]
      });

    },
    benefitsSection: {
      targetElement: $('section.benefits'),
      selector: $('section.benefits .selectors'),
      content: $('section.benefits .content'),
      isActive: Number($('section.benefits .selectors .item.active').attr('sid')),
      switch: function (id) {
        if (id == this.isActive) {
          return;
        } else {

          this.content.css('height', this.content.find('.item[sid="'+id+'"]').height() );
          this.selector.find('.item[sid="'+this.isActive+'"]').toggleClass('active');
          this.selector.find('.item[sid="'+id+'"]').toggleClass('active');
          this.content.find('.item[sid="'+this.isActive+'"]').toggleClass('active');
          this.content.find('.item[sid="'+id+'"]').toggleClass('active');
          this.isActive = id;
        }
      }
    },
    init: function () {
      this.onLoad(jetDeal.variables.viewportHeight, jetDeal.variables.spaceSections);
      $('section.benefits .selectors .item').each(function() {
        $(this).click(function() {
          jetDeal.behaviours.frontPage.benefitsSection.switch($(this).attr('sid'));
        });
      });
      this.emptyLegs();
    }
  },
  topBar: {
    targetElement: $('#hamburger'),
    hamburger: $('#hamburger'),
    isOpen: false,
    content: $('.universe'),
    menu: $('header.menus'),
    toggle: function () {
      if (this.isOpen) {
        this.targetElement.addClass('js-closed');
        this.targetElement.removeClass('js-open');
        this.hamburger.removeClass('open');
        this.menu.removeClass('visible');
        this.content.removeClass('js-menu-opened');
        this.isOpen = false;
      } else {
        this.targetElement.addClass('js-open');
        this.targetElement.removeClass('js-closed');
        this.hamburger.addClass('open');
        this.menu.addClass('visible');
        this.content.addClass('js-menu-opened');
        this.isOpen = true;
      }
    },
    listen: function () {
      $(document).keyup(function(e) {
         if (e.keyCode == 27 && this.isOpen) {
           this.toggle();
        }
      }.bind(this));

      this.content.click(function() {
        if(this.isOpen) {
          this.toggle();
        }
      }.bind(this));

    }
  },
  forms: function (element) {
    console.log(element);
    if (element == undefined) {
      element = $(document);
    }

    $(element).find('input[type="text"],input[type="email"],input[type="password"],input[type="number"], select, textarea, .chosen-container').each(function() {

      //Inits chosen
      if ($(this).hasClass('chosen-select') && !$(this).attr('multiple')) {
        $(this).chosen({
          placeholder_text_single: '&nbsp',
          width: '100%'
        });

        var chosenContainer = $(this).parent();

        chosenContainer.focusin(function() {
          if ($(this).find('.chosen-single >span').html() == '&nbsp;') {
            chosenContainer.toggleClass('active');
          }
        });
        chosenContainer.focusout(function() {
          if ($(this).find('.chosen-single >span').html() == '&nbsp;') {
            chosenContainer.toggleClass('active');
          }
        });

      } else if ($(this).attr('multiple')) {
        $(this).chosen({
          placeholder_text_single: '&nbsp',
          width: '100%',
          max_selected_options: 6,
          allow_single_deselect: true
        });

        var chosenContainer = $(this).parent();

        chosenContainer.focusin(function() {
          if ($(this).find('.chosen-choices >li.search-choice').length == 0) {
            chosenContainer.toggleClass('active');
          }
        });
        chosenContainer.focusout(function() {
          if ($(this).find('.chosen-choices >li.search-choice').length == 0) {
            chosenContainer.toggleClass('active');
          }
        });
      }

      // If its textarea with autorezise
      if ($(this).attr('rows') == 1) {

        $(this).keydown(function(event) {
          switch ($(this).val().length) {
            case 49:
              $(this).attr('rows', 1);
              break;
            case 50:
              $(this).attr('rows', 2);
              break;
            case 99:
              $(this).attr('rows', 2);
              break;
            case 100:
              $(this).attr('rows', 3);
              break;
            case 149:
              $(this).attr('rows', 4);
              break;
            case 150:
              $(this).attr('rows', 4);
              break;
            case 199:
              $(this).attr('rows', 4);
              break;
            case 200:
              $(this).attr('rows', 5);
              break;
            case 249:
              $(this).attr('rows', 5);
              break;
            case 250:
              $(this).attr('rows', 6);
              break;

          }
        });
      }


      $(this).focusin(function() {
        if ($(this).val() == '') {
          $(this).parent().toggleClass('active');
        }
      });
      $(this).focusout(function() {
        if ($(this).val() == '') {
          $(this).parent().toggleClass('active');
        }
      });
    });

    $('input.date').each(function() {
      return new Pikaday({
        field: $(this)[0],
        format: 'MM/DD/YYYY'
      });
    });

    $('input.time').each(function() {
      $(this).timepicker({
        timeFormat: 'H:i',
        show2400: true
      });
    });

  },
  multilegs: {
    confirmation: false,
    template: $('#multilegs .multileg').html(),
    container: $('#multilegs'),
    addButton: $('#newmultileg'),
    newMultileg: function () {
      var context = this;
      jetDeal.behaviours.modals.openModal(this.addButton, function () {
        var appendEle = jQuery.parseHTML('<div class="multileg">' + context.template + '</div>');
        context.container.append(appendEle);
        jetDeal.behaviours.forms(appendEle);
      });
    },
    init: function () {
      var context = this;
      this.addButton.click(function() {
        context.newMultileg();
      });
    }
  },
  notifications: {
    targets: $('.notification'),
    init: function () {
      if (this.targets.length > 0) {
        this.targets.each(function() {
          var messageObj = $(this),
              mouseHovering = false;

          messageObj.hover(function () {mouseHovering = true;},function () {mouseHovering = false;});

          messageObj.addClass('show');
          messageObj.find('.close').click(function() {
            messageObj.removeClass('show');
          });
          setTimeout(function () {
            if (!mouseHovering) {
              messageObj.removeClass('show');
            } else {
              messageObj.mouseleave(function() {
                $(this).removeClass('show');
              });
            }
          }, 6000);
        });
      }
    },
  },
  modals: {
    type:  ['success','warning','alert','info'],
    preventDefault: true,
    functionality: ['confirm','prompt','authorize','notify'],
    targets: $('button[hasModal], a[hasModal], input[hasModal]'),
    renderTemplate: function (functionality, type, message) {
      switch (functionality) {
        case 'confirm':
          return '<div class="notification modal '+ type +'">'+
                  '<div>' + message + '</div>'+
                  '<button class="btn" value="yes">Yes</button>' +
                  '<button class="btn" value="no">No</button>' +
                 '</div>';
          break;
        case 'authorize':
          return '<div class="notification modal '+ type +'">'+
                  '<a href="#0" class="close"></a>' +
                  '<div>' + message + '</div>'+
                  '<button class="btn float-left">I accept</button>' +
                  '<button class="btn float-left">Get me out of here!</button>' +
                 '</div>';
          break;
        default:
          return '<div class="notification modal '+ type +'">'+
                  '<a href="#0" class="close"></a>' +
                  '<div>' + message + '</div>'+
                  '<button value="no" class="btn float-right">Ok</button>' +
                 '</div>';
      }
    },
    openModal: function (obj, yesCallback, noCallback) {
      var link = obj.attr('href'),
          yesBtn = undefined,
          noBtn = undefined,
          functionality = obj.attr('modalfunc'),
          type = obj.attr('modaltype'),
          message = obj.attr('modalmessage'),
          modal = jetDeal.behaviours.modals.renderTemplate(functionality, type, message),
          context = this;

      $('.universe').append(modal);

      modal = $('.universe .notification.modal');
      yesBtn = modal.find('button[value="yes"]');
      noBtn = modal.find('button[value="no"]');

      setTimeout(function () {
        modal.addClass('show');
        $('main.content').addClass('faded');
      }, 100);

      yesBtn.click(function() {
        if (yesCallback != undefined) {
          yesCallback();
          modal.removeClass('show');
          $('main.content').removeClass('faded');
          setTimeout(function () {
            modal.remove();
          }, 500);
        } else {
          context.preventDefault = false;

          if (link == undefined) {
            console.log('triggering click');
            obj.trigger('click');
          } else {
            window.location = link;
          }
        }
      });

      noBtn.click(function(event) {
        if (noCallback != undefined) {
          noCallback();
        }

        modal.removeClass('show');
        $('main.content').removeClass('faded');
        setTimeout(function () {
          modal.remove();
        }, 500);
      });
    },
    init: function () {
      var context = this;
      this.targets.each(function() {
        $(this).click(function (event) {
          if (context.preventDefault) {
            event.preventDefault();
          }
          context.openModal($(this));
        });
      });
    }
  },
  dashboard: {
    menu: {
      nano: function (isExpanded) {
        var getHeight = function (content) {
          var height = 0;

          $('.sidebar').find('ul.nested').each(function() {
            $(this).css('display','block');
          });

          height = content.height();

          $('.sidebar').find('ul.nested').each(function() {
            $(this).css('display','none');
          });

          return height;


        };
        $('.nano').each(function() {

            var isExpanded = false;

            if (getHeight($(this).find('.nano-content')) > 500) {

            $(this).click(function() {
              var nanoContent = $(this).find('.nano-content');
              if (isExpanded) {
                console.log('closing nano');
                nanoContent.removeAttr('style');
                $(this).removeAttr('style');
                $(this).nanoScroller({destroy: true});
                isExpanded = false;
              } else {
                console.log('opening nano');
                setTimeout(function () {
                  nanoContent.css({
                    position: 'absolute',
                    top: '67px',
                    left: 0,
                    right: 0,
                    bottom: 0
                  });
                  $(this).nanoScroller({ preventPageScrolling: true });
                  isExpanded = true;
                }.bind($(this)),300);
              }
            });
          }
        });
      }
    },
    auctionTabs: function () {
      $('a.next').each(function () {
        $(this).click(function () {
          var href = $(this).attr('href');
          $('#reverseauction-tabs').foundation('selectTab', href);
          window.setTimeout(function () { window.scrollTo(0, 100);}, 5);
         
        });
      });
    }
  },
  chat: {
    disclaimer: function () {
      var el = $('<a class="button success float-right" href="#0" hasmodal="" modalfunc="confirm" modaltype="info" modalmessage="<h3>Disclaimer</h3><p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p><h6>Do you wish to continue?</h6>"></a>');

      var messageCount = $('.messages').find('.message.mine');

      if (messageCount.length == 0) {
        $('a[href="#chat"]').click(function (e) {
          jetDeal.behaviours.modals.openModal(el, function () {
            $('#chat').removeClass('chat-inactive');
            $('#chat, a[href="#chat"]').unbind();
          }, function () {
            $('#chat').addClass('chat-inactive');

            $('#chat').click(function () {
              jetDeal.behaviours.modals.openModal(el, function () {
                $('#chat').removeClass('chat-inactive');
                $('#chat, a[href="#chat"]').unbind();
              }, function () {
                $('#chat').addClass('chat-inactive');
              });
            });

          });
        });
      } else {
        $('#chat').removeClass('chat-inactive');
      } 

      
    }
  },
  init: function () {

    try {
      document.getElementById('hamburger').addEventListener('click', function () {
        this.topBar.toggle();
      }.bind(this));
    } catch (e) {
      console.log(e);
    }

    //Init JqueryFullpage
    try {
      $('main.content').fullpage({
        autoScrolling: true,
        navigation: true,
        navigationTooltips: ['Welcome','Last Calls','Available to you','How does JetDeal work','Benefits','Contacts']
      });
    } catch (error) {
      console.log(error);
    }

    //Init fancybox's lightbox
    if ($().fancybox != undefined) {
      $('.fancybox').fancybox();
    }

    this.topBar.listen();
    //this.dashboard.menu.nano(this.dashboard.menu.isExpanded);
    this.forms();
    this.notifications.init();
    this.multilegs.init();
    this.modals.init();
    this.chat.disclaimer();
    this.dashboard.auctionTabs();

  }
};

//----------------------------Script initialization-----------------------------

$(document).foundation();

jetDeal.behaviours.init();
jetDeal.behaviours.frontPage.init();

// $('#hamburger').click(function() {
//   topbarBehaviour.toggle();
// });
//
